import { isLogin, isLoginAdmin } from '@src/utils/base';
import { createRouter, createWebHistory } from 'vue-router';
import type { RouteRecordRaw } from 'vue-router';
import 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@src/components/main.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/guiguigo',
    name: 'guiguigo',
    component: () => import('@src/components/domain/guiguigo.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true,
      newWindow: true,
    },
  },
  {
    path: '/fireworks',
    name: 'fireworks',
    component: () => import('@src/components/domain/fireworks.vue'),
    meta: {
      requiresAuth: false,
      isAdmin: false,
      newWindow: true,
    },
  },
];

declare module 'vue-router' {
  interface RouteMeta {
    /** 管理员权限 */
    isAdmin?: boolean;
    /** 登录权限 */
    requiresAuth: boolean;
    /** 是否新窗口打开 */
    newWindow?: boolean;
  }
}

export const router = createRouter({
  history: createWebHistory(), // 历史记录模式
  routes,
});

// 路由守卫
router.beforeEach(async (to) => {
  console.log('路由守卫：', to);

  // 判断是否需要登录
  if (to.meta.requiresAuth && to.fullPath !== '/') {
    if (to.meta.isAdmin) {
      // 判断是否是管理员
      return await isLoginAdmin();
    } else if (isLogin()) {
      // 判断是否登录
      return true;
    } else {
      return { name: 'Home' };
    }
  }
  return true;
});
